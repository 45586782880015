import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
// import { FiFrown, FiTrash2, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
// import { Spinner } from './styles';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import { Container, Content } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

interface Config {
  id: number;
  image: string;
  image_red: string;
  local: string;
  abreviation: string;
  phone: string;
  whatsapp: string;
  email: string;
  andress: string;
  hours_interval: string;

  imageFile?: File; // Campo opcional para o arquivo da imagem
  imageRedFile?: File;
}

const Config: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [configs, setConfigs] = useState<Config[]>([]);
  const [formData, setFormData] = useState<Config | null>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<File | null>(null);
  const [imageRed, setImageRed] = useState<File | null>(null);
  const formRef = useRef<FormHandles>(null);
  useEffect(() => {
    async function loadConfigs() {
      try {
        const response = await api.get('/provider/config');
        setConfigs(response.data);

        const configToEdit = response.data.find(
          (config: Config) => config.id === 1,
        );
        if (configToEdit) {
          setFormData(configToEdit);
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os dados',
          description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
        });
      }
    }
    loadConfigs();
  }, [addToast]);

  const handleSubmit = async (data: Config) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        local: Yup.string().required('Local obrigatório'),
        abreviation: Yup.string().required('Abreviação obrigatória'),
        phone: Yup.string().required('Telefone obrigatório'),
        whatsapp: Yup.string()
          .required('WhatsApp obrigatório')
          .matches(
            /^55\d{9,12}$/,
            'WhatsApp inválido (Deve ter 11 ou 12 dígitos e os dois primeiros são 55)',
          ),
        email: Yup.string()
          .email('E-mail inválido')
          .required('E-mail obrigatório'),
        andress: Yup.string().required('Endereço obrigatório'),
        hours_interval: Yup.string().required('Intervalo de Horas obrigatório'),

      });

      await schema.validate(data, { abortEarly: false });

      const formData = new FormData();

      //
      Object.keys(data).forEach(key => {
        if (key in data) {
          const value = data[key as keyof Config]; // Usando type guard
          if (value !== undefined) {
            formData.append(key, value.toString());
          }
        }
      });

      if (image) {
        formData.append('image', image);
      }
      if (imageRed) {
        formData.append('image_red', imageRed);
      }

      await api.put(`/provider/config/1`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('FORM DATA' + formData);
      addToast({
        type: 'success',
        title: 'Configuração atualizada com sucesso!',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(validationError => {
          addToast({
            type: 'error',
            title: 'Erro de validação',
            description: validationError.message,
          });
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar a configuração, tente novamente.',
        });
      }
    }
  };

  const handleImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      if (field === 'image') {
        setImage(file);
      } else if (field === 'image_red') {
        setImageRed(file);
      }
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Content>
          {/* <Scroll> */}
          <div>
            <h1>Configurações</h1>
            {formData && (
              <Form initialData={formData} onSubmit={handleSubmit}>
                <label htmlFor="local">Local:</label>
                <Input id="local" name="local" placeholder="Local" />

                <label htmlFor="abreviation">Abreviação:</label>
                <Input
                  id="abreviation"
                  name="abreviation"
                  placeholder="Abreviação"
                />

                <label htmlFor="phone">Telefone:</label>
                <Input id="phone" name="phone" placeholder="Telefone" />

                <label htmlFor="whatsapp">WhatsApp:</label>
                <Input id="whatsapp" name="whatsapp" placeholder="WhatsApp" />

                <label htmlFor="email">Email:</label>
                <Input id="email" name="email" placeholder="Email" />

                <label htmlFor="andress">Endereço:</label>
                <Input id="andress" name="andress" placeholder="Endereço" />
                <br />

                <label htmlFor="hours_interval">Intervalo de Horas:</label>
                <Input
                  id="hours_interval"
                  name="hours_interval"
                  placeholder="Intervalo de Horas"
                />
                <br />
                <label htmlFor="image">Imagem:</label>
                <input
                  id="image"
                  type="file"
                  onChange={e => handleImageUpload(e, 'image')}
                />
                <br />

                <label htmlFor="image_red">Imagem Mob:</label>
                <input
                  id="image_red"
                  type="file"
                  onChange={e => handleImageUpload(e, 'image_red')}
                />

                <Button type="submit">Salvar</Button>
              </Form>
            )}
          </div>
          {/* {loading ? (
              <Spinner />
            ) : configs.length > 0 ? (
              configs.map(config => (
                <div key={config.id}>
                  <div>
                    <h1>{config.local}</h1>
                    <h2>{config.abreviation}</h2>
                    <h3>{config.phone}</h3>
                    <h4>{config.whatsapp}</h4>
                    <h5>{config.email}</h5>
                    <h6>{config.andress}</h6>
                  </div>
                  <IconButton
                    onClick={() => history.push(`/config/edit/${config.id}`)}
                  >
                    <FiEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => history.push(`/config/delete/${config.id}`)}
                  >
                    <FiTrash2 />
                  </IconButton>
                </div>
              ))
            ) : (
              <EmptyList>
                <FiFrown />
                <span>Nenhuma configuração cadastrada</span>
              </EmptyList>
            )} */}
          {/* </Scroll> */}
        </Content>
      </Container>
    </>
  );
};

export default Config;
