import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FiArchive, FiFileText } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SectorInput from '../../Schedules/Modal/SectorInput';

import { ModalContent, ModalOverlay, ModalWrapper } from './styles';

export interface EditSubjectData {
  id: number;
  title: string;
  description: string;
  sector_id: number;
  isActive: number;
}

interface EditSubjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleEditSubject: (editSubject: EditSubjectData) => Promise<void>;
  sectors: any[];
  subject: {
    id: number;
    title: string;
    description: string;
    sector_id: number;
    isActive: number;
  };
}

const EditSubjectModal: React.FC<EditSubjectModalProps> = ({
  isOpen,
  onClose,
  handleEditSubject,
  subject,
  sectors,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isActive, setIsActive] = useState<number>(subject.isActive);
  async function handleSubmit(data: EditSubjectData): Promise<void> {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        id: Yup.string().required('ID obrigatorio'),
        title: Yup.string().required('Titulo obrigatorio'),
        description: Yup.string().required('Descrição obrigatorio'),
        sector_id: Yup.string().required('Setor obrigatorio'),
        isActive: Yup.string().required('É Ativo obrigatorio'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      data.isActive = Number(data.isActive);
      await handleEditSubject(data);
      console.log('Valores HANDLESUBMIT%%%%%%%%%%%%%%', data);
      onClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <Form ref={formRef} onSubmit={handleSubmit} initialData={subject}>
                <h1>Editar Assunto</h1>
                <Input name="id" readOnly type="hidden" />
                <Input name="title" icon={FiArchive} placeholder="Titulo" />
                <Input
                  name="description"
                  icon={FiFileText}
                  placeholder="Descrição"
                />
              <label>
                  Ativo
                  <input
                    type="checkbox"
                    name="isActive"
                    checked={isActive === 1}
                    onChange={e => setIsActive(e.target.checked ? 1 : 0)}
                  />
                </label>
                <Input name="isActive" type="hidden" value={isActive} />
               <SectorInput name="sector_id" sectors={sectors} />
                <Button type="submit">Salvar</Button>
                <Button onClick={onClose}>Cancelar</Button>{' '}
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

export default EditSubjectModal;
