// criar pagina de schedules
import React, { useEffect, useState, useMemo } from 'react';
import { FiCalendar } from 'react-icons/fi';
import moment from 'moment-timezone';
import noDataImage from '../../../assets/nenhum_dado.png'; // Importe a imagem

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
//import EditUserModal, { EditUserData } from './Modal';
import { Spinner } from './styles'; // Adicione Spinner à lista de importações

import Header from '../../../components/Header';
import { Chart } from 'react-google-charts';

import {
  Container,
  Content,
  ChartsContainer,
  Container1,
  Container2,
  DateInput,
  ChartBox,
  DateInputWrapper,
  SelectWrapper,
} from './styles';

import SectorSelectInput from '../../components/SectorSelectInput';

interface DayOff {
  id: number;
  date: Date;
  description: string;
}

interface AgendamentoSetor {
  sector_title: string;
  count: number;
}

interface AppointmentStatus {
  status_title: string;
  count: string;
}

interface AppointmentSubjects {
  appointment_subject: string;
  count: string;
}

const Dashboard: React.FC = () => {
  // const { user } = useAuth();
  // const history = useHistory();
  // const [dayOffs, setDayOffs] = useState<DayOff[]>([]);
  const [isMounted, setIsMounted] = useState(true); // Variável de controle
  const { addToast } = useToast();
  // const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição do modal
  // const [showEditModal, setShowEditModal] = useState(false);
  // const [sectors, setSectors] = useState<any[]>([]); // Estado para armazenar os setores

  const [countAppointmentsByDate, setCountAppointmentsByDate] = useState<
    AgendamentoSetor[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [appointmentStatus, setAppointmentStatus] = useState<
    AppointmentStatus[]
  >([]);

  const [appointmentSubjects, setAppointmentSubjects] = useState<
    AppointmentSubjects[]
  >([]);
  const [chartData, setChartData] = useState<Array<[string, number]>>([]);

  console.log('-----------------setChartData', chartData); // carregar o endpoint de dados do graficos
  const [startDate, setStartDate] = useState(
    moment().tz('America/Sao_Paulo').startOf('day').toDate(),
  );
  const [endDate, setEndDate] = useState(
    moment().tz('America/Sao_Paulo').startOf('day').toDate(),
  );
  const formattedDateStart = useMemo(() => {
    return moment(startDate).format('YYYY-MM-DD');
  }, [startDate]);

  const formattedDateEnd = moment(endDate).format('YYYY-MM-DD');
  console.log('DATA INICIO FORMATADA' + formattedDateStart);
  // const formattedDateEnd = moment(startDate).format('yyyy-MM-dd');
  console.log('DATA FIM FORMATADA' + formattedDateEnd);
  console.log('startDate', startDate);
  console.log('endDate', endDate);
  // const [chartData, setChartData] = useState<Array<[string, number]>>([
  //   ['Setor', 'Quantidade'],
  //   // outros dados iniciais do gráfico
  // ]);
  const [selectedSector, setSelectedSector] = useState<number | null>(null);

  const colors = ['#b87333', 'silver', 'gold', '#blue', 'red'];

  const colors2 = [
    '#e32636',
    '#a94444',
    '#cb5e8a',
    '#de3163',
    '#e32636',
    '#ff6347',
    '#e2725b',
    '#fa7f72',
    '#f08080',
    '#c26767',
  ];

  const [SectorSelectInputValue, setSectorSelectInputValue] = useState('');

  useEffect(() => {
    const data = async () => {
      try {
        const response = await api.get('/provider/count_total', {
          params: {
            date: new Date().getDate(),
          },
        });
        const newData = response.data.map((item: any) => [
          item.setor,
          item.quantidade,
        ]);

        setCountAppointmentsByDate([...chartData, ...newData]);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar os dados ',
          description: 'Ocorreu um erro ao carregar os dados, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    };

    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    setKey(Math.random()); // Atualizar a chave quando a data é alterada
  }, [startDate, endDate]);

  const loadChartData = async (startDate: Date, endDate: Date) => {
    try {
      setLoading(true);
      setChartData([]); // Limpar os dados antigos
      setSelectedSector(null); // Definir o setor como nul  lsetSelectedSector(''); // Definir o setor como vazio

      const formattedDateStart = moment(startDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      const formattedDateEnd = moment(endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');

      console.log('DATA INICIO FORMATADA***********************' + startDate);
      console.log('DATA INICIO FORMATADA***********************' + endDate);

      console.log(
        'DATA INICIO FORMATADA***********************' + formattedDateStart,
      );
      console.log(
        'DATA INICIO FORMATADA***********************' + formattedDateEnd,
      );

      if (moment(formattedDateStart).isAfter(formattedDateEnd)) {
        console.log('A data inicial é maior que a data final');
        return;
      }

      const params = {
        dateInicio: formattedDateStart,
        dateFim: formattedDateEnd,
        _: key, // Usar a chave como timestamp para evitar cache
      };

      console.log(params); // Log params
      const response = await api.get(
        '/provider/appointments/count_total_interval',
        {
          params,
        },
      );
      console.log('-----------------DATA FROM API', data); // Log newData

      const dataFromApi = response.data.map((item: any) => [
        item.sector_title,
        parseInt(item.count, 10),
      ]);

      console.log('-----------------DATA FROM API', dataFromApi); // Log newData

      const newData = [['Setor', 'Quantidade'], ...dataFromApi];
      console.log('-----------------newData', newData); // Log newData
      setChartData(newData);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os dados',
        description:
          'Ocorreu um erro ao carregar os dados, tente novamente.' + err,
      });
    } finally {
      setLoading(false);
    }
  };

  // //__________________________________________________Grafico de barra

  // useEffect(() => {
  const loadAppointmentStatus = async (selectedSector: number | null) => {
    console.log(selectedSector);
    try {
      setLoading(true);

      const formattedDateStart = moment(startDate).format('YYYY-MM-DD');
      const formattedDateEnd = moment(endDate).format('YYYY-MM-DD');
      console.log(
        'DATA INICIO FORMATADA***********************' + formattedDateStart,
      );
      console.log(
        'DATA INICIO FORMATADA***********************' + formattedDateEnd,
      );

      const response = await api.get(
        '/provider/appointments/count_appointments_sector',
        {
          params: {
            dateInicio: formattedDateStart,
            dateFim: formattedDateEnd,
            sectorId: selectedSector,
          },
        },
      );

      setAppointmentStatus(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  //------------------------------------------------------------------------------------
  const loadAppointmentSubjects = async (selectedSector: number | null) => {
    console.log(selectedSector);
    try {
      setLoading(true);

      const formattedDateStart = moment(startDate).format('YYYY-MM-DD');
      const formattedDateEnd = moment(endDate).format('YYYY-MM-DD');

      const response = await api.get(
        '/provider/appointments/count_appointments_subject_sector',
        {
          params: {
            dateInicio: formattedDateStart,
            dateFim: formattedDateEnd,
            sectorId: selectedSector,
          },
        },
      );

      setAppointmentSubjects(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  //   loadAppointmentStatus();
  // }, [startDate, endDate, selectedSector]);
  useEffect(() => {
    loadAppointmentStatus(selectedSector);
  }, [startDate, endDate, selectedSector]);

  useEffect(() => {
    loadAppointmentSubjects(selectedSector);
  }, [startDate, endDate, selectedSector]);

  const data = [
    ['Status', 'Qtd por status', { role: 'style' }],
    ...appointmentStatus.map((status, index) => [
      status.status_title,
      parseInt(status.count, 10),
      colors[index % colors.length],
    ]),
  ];

  const data2 = [
    ['Assunto', 'Qtd por assunto', { role: 'style' }],
    ...appointmentSubjects.map((subject, index) => [
      subject.appointment_subject,
      parseInt(subject.count, 10),
      colors2[index % colors2.length],
    ]),
  ];
  console.log('data2    ', data2);
  // ---------------------------------------------------------------------------------
  const options = {
    title: 'Quantidade de agendamentos  (Não contabiliza os cancelados)',
    is3D: true,
    pieHole: 0.4,
  };

  const options2 = {
    title: 'Quantidade de agendamentos por status',
    chartArea: { width: '60%' },
    hAxis: {
      title: 'Status',
      minValue: 0,
    },
    vAxis: {
      title: 'Quantidade',
    },
  };
  console.log('chartData   ', chartData);

  // useEffect(() => {
  //   async function loadSectors() {
  //     try {
  //       const response = await api.get('/sectors'); // Certifique-se de que esta rota está correta
  //       setSectors(response.data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  //   // Verifica se o modal está aberto antes de carregar os setores

  //   loadSectors();
  // });
  // // Função para abrir o modal de edição
  // function handleSelectSector(e: React.ChangeEvent<HTMLSelectElement>): void {
  //   const newSelectedSector = Number(e.target.value);
  //   setSelectedSector(newSelectedSector);
  //   // const newStartDate = subDays(startDate, 2);
  //   // const newEndDate = new Date(formattedDateEnd);
  //   // loadChartData(newStartDate, newEndDate)
  //   const formattedStartDate = new Date(formattedDateStart);
  //   const formattedEndDate = new Date(formattedDateEnd);
  //   console.log('data 1  ', formattedStartDate + '   /n   data 2' + startDate);
  //   // Agora, use 'newSelectedSector' diretamente ao invés de 'selectedSector'
  //   // loadChartData(startDate, endDate);
  //   loadAppointmentStatus(newSelectedSector);
  //   console.log(
  //     'carregar status ###############',
  //     loadAppointmentStatus(newSelectedSector),
  //   );
  //   loadAppointmentSubjects(newSelectedSector);
  //   console.log('selectedSector ###############', newSelectedSector);

  //   console.log(
  //     'loadChartData ******************************' +
  //       loadChartData(startDate, endDate),
  //   );
  //   loadChartData(formattedEndDate, formattedEndDate);
  // }
  // }
  // }
  // }

  function handleSelectSector(e: React.ChangeEvent<HTMLSelectElement>): void {
    const newSelectedSector = Number(e.target.value);
    setSelectedSector(newSelectedSector);

    const formattedStartDate = new Date(formattedDateStart);
    const formattedEndDate = new Date(formattedDateEnd);

    loadAppointmentStatus(newSelectedSector);
    loadAppointmentSubjects(newSelectedSector);
    loadChartData(formattedStartDate, formattedEndDate);
  }

  useEffect(() => {
    const formattedStartDate = new Date(formattedDateStart);
    const formattedEndDate = new Date(formattedDateEnd);
    loadChartData(formattedStartDate, formattedEndDate);

    console.log(
      'loadChartData ******************************' +
        loadChartData(formattedStartDate, formattedEndDate).toString(),
    );
  }, [formattedDateStart, formattedDateEnd]);

  // const data3 = [
  //   ['Element', 'Quantidade'],
  //   ['React', 100],
  //   ['Angular', 80],
  //   ['Vue', 50],
  // ];

  const options3 = {
    title: 'Quantidade de agendamento por assunto ',
    hAxis: {
      title: 'Quantidade',
    },
    vAxis: {
      title: 'Assunto',
    },
  };
  console.log('selectedSector ###############', selectedSector);
  return (
    <>
      <Header />
      <Container>
        <Content>
          <h2
            style={{
              fontSize: '2rem',
              marginBottom: '1rem',
            }}
          >
            DASHBOARD
          </h2>
          {/* QUERO COLOCAR UM DATE PICKER AQUI */}
          <Container1>
            <DateInputWrapper>
              <FiCalendar className="calendar-icon" />
              <DateInput
                selected={startDate}
                onChange={(date: Date) =>
                  setStartDate(
                    moment
                      .tz(date, 'America/Sao_Paulo')
                      .startOf('day')
                      .toDate(),
                  )
                }
                dateFormat="yyyy-MM-dd"
                showTimeSelect={false}
                timeIntervals={15}
                timeCaption="Hora"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </DateInputWrapper>
            <DateInputWrapper>
              <FiCalendar className="calendar-icon" />
              <DateInput
                selected={endDate}
                onChange={(date: Date) =>
                  setEndDate(
                    moment
                      .tz(date, 'America/Sao_Paulo')
                      .startOf('day')
                      .toDate(),
                  )
                }
                dateFormat="yyyy-MM-dd"
                showTimeSelect={false}
                timeIntervals={15}
                timeCaption="Hora"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </DateInputWrapper>
          </Container1>
          <ChartsContainer>
            <ChartBox>
              {loading ? (
                <p>Carregando...</p>
              ) : chartData.length <= 1 ? (
                <div>
                  <img
                    src={noDataImage}
                    alt="Nenhum dado"
                    style={{ width: '350px' }}
                  />
                  <p>Nenhum dado nesse intervalo de data</p>
                </div>
              ) : (
                <Chart
                  chartType="PieChart"
                  data={chartData}
                  options={options}
                  width={'100%'}
                  height={'500px'}
                />
              )}
            </ChartBox>
            {/* ---------------------------------------------------------------------------------------------- */}
          </ChartsContainer>

          <Container2>
            <SelectWrapper>
              <SectorSelectInput name="sector" onChange={handleSelectSector} />
            </SelectWrapper>
          </Container2>

          <ChartsContainer>
            {/* ---------------------------------------------------------------------------------------------- */}

            <ChartBox>
              {loading ? (
                <p>Carregando...</p>
              ) : appointmentStatus.length === 0 ? (
                <div>
                  <img
                    src={noDataImage}
                    alt="Nenhum dado"
                    style={{ width: '350px' }}
                  />

                  <p>Nenhum dado nesse intervalo de data para esse setor.</p>
                </div>
              ) : (
                <Chart
                  chartType="ColumnChart"
                  data={data}
                  options={options2}
                  width={'100%'}
                  height={'400px'}
                />
              )}
            </ChartBox>
            <ChartBox>
              {loading ? (
                <Spinner /> // Exibe o spinner enquanto os dados estão carregando
              ) : data2.length <= 1 ? ( // Verifica se `data2` tem apenas o cabeçalho ou está vazio
                <div>
                  <img
                    src={noDataImage}
                    alt="Nenhum dado"
                    style={{ width: '350px' }}
                  />
                  <p>Nenhum dado nesse intervalo de data para esse setor.</p>
                </div>
              ) : (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="400px"
                  data={data2}
                  options={options3}
                />
              )}
            </ChartBox>
          </ChartsContainer>
        </Content>
      </Container>
    </>
  );
};

export default Dashboard;
