import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiArchive, FiFileText, FiMapPin } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ModalContent, ModalOverlay, ModalWrapper } from './styles';
import SectorSelectInput from '../../../components/SectorSelectInput';
import SectorInput from './SectorInput';
import { setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';

export interface NewHourOff {
  id: number;
  date: Date;
  description: string;

  hourInitial: string;
  hourFinal: string;
  sector_id: string;
  sector: {
    id: number;
    title: string;
  };
}

interface AddHourOffModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddHourOff: (newHourOff: NewHourOff) => Promise<void>;
  sector_id: any[]; // Adicione esta linha
}

const today = new Date();
const todayStartOfDay = setHours(
  setMinutes(setSeconds(setMilliseconds(today, 0), 0), 0),
  0,
);
// const [selectedSector, setSelectedSector] = useState<string | null>(null);
const AddHourOffModal: React.FC<AddHourOffModalProps> = ({
  isOpen,
  onClose,
  handleAddHourOff,
  sector_id,
  // sector deve seror do tipo sector_id
}) => {
  const formRef = useRef<FormHandles>(null);
  const [selectedSector, setSelectedSector] = useState<string | null>(null);

  const handleSelectedSectorChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSector(event.target.value);
  };

  async function handleSubmit(data: NewHourOff): Promise<void> {
    console.log('Valores HANDLESUBMIT&&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        date: Yup.date()
          .min(todayStartOfDay, 'A data não pode ser anterior a hoje')
          .required('Data obrigatória'),
        description: Yup.string().required('Descrição obrigatória'),
        hourInitial: Yup.string().required('Hora Inicial obrigatória'),
        hourFinal: Yup.string().required('Hora Final obrigatória'),
        sector_id: Yup.string(),
      });
      console.log('Schema      &&&&&&&&&&&&&&&&&&&&&&&&&', schema); // Adicione este console.log
      await schema.validate(data, { abortEarly: false });

      console.log('DATA +++++++++', data); // Adicione este console.log

      await handleAddHourOff(data);
      console.log('DATA SECTORS', data.sector_id); // Adicione este console.log
      onClose();
    } catch (err) {
      console.log('DATA 2 &&&&&&&&&&&&&&&&&&&&&&&&&', data); // Adicione este console.log

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWrapper>
            <ModalContent>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Adicionar Horário Off</h1>
                <Input
                  name="date"
                  icon={FiArchive}
                  type="date"
                  placeholder="Data"
                />
                <Input
                  name="description"
                  icon={FiFileText}
                  type="text"
                  placeholder="Descrição"
                />

                <div className="time-picker-container">
                  <Input
                    name="hourInitial"
                    type="time"
                    placeholder="Hora inicial"
                  />{' '}
                  {/* Nome do campo atualizado */}
                </div>
                <div className="time-picker-container">
                  <Input
                    name="hourFinal"
                    type="time"
                    placeholder="Hora final"
                  />{' '}
                  {/* Nome do campo atualizado */}
                </div>

                <SectorInput name="sector_id" sectors={sector_id} />

                <Button type="submit">Adicionar</Button>
                <Button type="button" onClick={onClose}>
                  Cancelar
                </Button>
              </Form>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};
export default AddHourOffModal;
